import { Alert, CardDetail, CardDetailLoading, Loading } from '../../../common'
import LoadingDetails from './Loading'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { OFFERS_BZB_TYPE } from '../../../utils/constant'
import { LUCKY_DRAW_TYPE, ModalLuckyDraw } from './Modal'
import { Transition } from 'react-transition-group'
import { DATE_FORMAT, getDate } from '../../../utils/date'
import TimerDisplay from './TimerDisplay'
import BarcodeQRCode from './BarcodeQRCode'
import { Button } from '../../../common'
import { getBZBCouponDetails } from '../../../services/my-coupon-bff'
import { getBZBToken } from '../../../components/functions'
import { Coupon, CouponsBzbDetails } from '../../../services/my-coupon-bff/types'
import { ModalConfirmCopyCode } from '../couponsLotusDetails/Modal'

type Props = {
  loading: boolean
  coupon: Coupon
  successMessage?: string
  nodeRef?: any
  isShowingSelectedCoupon?: boolean
  durationTransition?: number
}

const CouponsSpecialStoreDetails: React.FC<Props> = ({ loading, coupon, successMessage, nodeRef, isShowingSelectedCoupon, durationTransition }) => {
  const { couponId, couponType, acquireDate, privilegeMessage, isExpired = false, expireIn = 0, couponCode } = coupon || {}
  const styleFooter = 'fixed inset-x-0 bottom-0 z-[300] bg-white px-[12px] pt-[12px] pb-[8px] border-t-0.5 border-[#E0E0E0]'
  // State
  const [showSuccessMessage, setShowSuccessMessage] = useState<string>(successMessage || '')
  const [isShowModal, setIsShowModal] = useState(false)
  const [IsShowCopy, setIsShowCopy] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [isTimeout, setIsTimeout] = useState<boolean>(isExpired)
  const [typeOfLuckyDraw, setTypeOfLuckyDraw] = useState<LUCKY_DRAW_TYPE | null>()
  const [privilegeMessageOfLuckyDraw, setPrivilegeMessageOfLuckyDraw] = useState<string | null>()

  // useMemo
  const type = useMemo(() => {
    if (typeof couponCode === 'string' && couponCode.includes(';') && couponCode.split(';').length === 2) {
      return OFFERS_BZB_TYPE.REFILL
    } else {
      return couponType as OFFERS_BZB_TYPE
    }
  }, [couponType, couponCode])

  const labelRedeemDate = useMemo(() => {
    const formattedDate = acquireDate ? getDate(acquireDate, DATE_FORMAT) : null
    if (!formattedDate) return ''
    if (type === OFFERS_BZB_TYPE.CHARITY) {
      return `วันที่บริจาค : ${formattedDate}`
    }
    return `วันที่แลกใช้ : ${formattedDate}`
  }, [acquireDate, type])
  const url = useMemo(() => {
    if (!privilegeMessage) return
    const parts = privilegeMessage.split('|')
    return parts[8]
  }, [privilegeMessage])

  const isHideFooterOpenExternalLink = useMemo(() => {
    // Handle case All external link is null and coupons COUNTDOWN is expired
    return !url || (isExpired && OFFERS_BZB_TYPE.COUNTDOWN === type)
  }, [url, isExpired, type])

  const parseSerial = (code: string) => {
    const segments = code.split(';')
    console.log('segments', segments)
    return {
      pinInformation: segments[0], // SEGMENT_1
      displaySerial: segments[1] // SEGMENT_2
    }
  }

  const getTypeOfLuckyDraw = useCallback((hasWinner: boolean, isWinner: boolean) => {
    if (!hasWinner) return LUCKY_DRAW_TYPE.NOT_ANNOUNCE
    if (hasWinner && isWinner) return LUCKY_DRAW_TYPE.WINNER
    if (hasWinner && !isWinner) return LUCKY_DRAW_TYPE.NOT_WINNER
    return null
  }, [])

  const fetchCouponById = useCallback(
    async (id: string) => {
      setIsLoading(true)
      try {
        const bzbToken = getBZBToken()
        const response = (await getBZBCouponDetails(id, bzbToken)) as CouponsBzbDetails

        if (response) {
          const data = getTypeOfLuckyDraw(response.HasWinner || false, response.IsWinner || false)
          setPrivilegeMessageOfLuckyDraw(response.PrivilegeMessage)
          setTypeOfLuckyDraw(data)
        }
        setIsLoading(false)
        setIsShowModal(!isShowModal)
      } catch (e) {
        setIsLoading(false)
      }
    },
    [getTypeOfLuckyDraw, isShowModal]
  )

  const copyTextToClipboard = (text: string) => {
    const input = document.createElement('input')
    input.value = text
    input.setAttribute('readonly', 'readonly')
    document.body.appendChild(input)

    input.select()
    input.setSelectionRange(0, input.value.length)
    document.execCommand('copy')
    input.remove()
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 3000)
  }

  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage('')
        localStorage?.setItem('successMessage', '')
      }, 3000)
    }
  }, [showSuccessMessage, isShowingSelectedCoupon])

  if (isShowingSelectedCoupon && loading) {
    return <LoadingDetails />
  }
  console.log('coupon', coupon)
  return (
    <Fragment>
      <div
        id="offer-details-alert-no-internet-container"
        className={classNames('fixed flex flex-row h-full z-[1000] bottom-[-88%]', {
          hidden: !isCopied
        })}
      >
        <Alert idElement="offer-details-no-internet" description={'คัดลอกโค้ดสำเร็จ'} icon="check-white-circle" />
      </div>
      <Transition nodeRef={nodeRef} in={isShowingSelectedCoupon} timeout={500} mountOnEnter unmountOnExit>
        {(state: string) => (
          <div
            id="coupons-special-store-details-page"
            ref={nodeRef}
            className={classNames('bg-white fixed overscroll-y-contain z-[60] top-0 bottom-0 left-0 right-0 duration-' + durationTransition + 'transition-all translate-x-full', {
              'overflow-y-auto': !isShowModal,
              '!translate-x-0': state === 'entering' || state === 'entered',
              'mb-[64px]': !isHideFooterOpenExternalLink || OFFERS_BZB_TYPE.LUCKY_DRAW === type
            })}
          >
            <Loading idElement="coupons-special-store-details-loading" isShow={isLoading} />

            {loading ? (
              <CardDetailLoading idElement="coupons-special-store-card-loading" />
            ) : (
              <CardDetail idElement="coupons-special-details-card-detail" image={coupon?.media?.url} name={coupon?.couponName} startDate={coupon?.startDate} expiryDate={coupon?.expiryDate} description={coupon?.description.replace(/\n/g, '<br/>')} condition={coupon?.condition.replace(/\n/g, '<br/>')}>
                {labelRedeemDate ? (
                  <div className={'flex justify-center items-center rounded-lg bg-white-smoke mb-[12px]'}>
                    <p className="p-3 text-base text-[#424242] font-bold font-lotuss">{labelRedeemDate}</p>
                  </div>
                ) : (
                  <></>
                )}

                {[OFFERS_BZB_TYPE.REFILL].includes(type) && !isExpired ? (
                  <>
                    <div className={'flex flex-col rounded-lg bg-white-smoke px-[12px] py-[8px] mb-[12px]'}>
                      <p className="text-base text-[#424242] font-bold font-lotuss">Pin</p>
                      <div className="flex flex-row">
                        <div className="text-base text-[#424242] break-words w-[95%]">{parseSerial(coupon.couponCode || '')?.pinInformation}</div>
                        <img
                          src="/icons/copy-icon.svg"
                          alt="copy-icon"
                          className="w-[18px] active:opacity-50"
                          onClick={() => {
                            copyTextToClipboard(parseSerial(coupon.couponCode || '')?.pinInformation)
                          }}
                        />
                      </div>
                    </div>

                    <div className={'flex flex-col rounded-lg bg-white-smoke px-[12px] py-[8px]'}>
                      <p className="text-base  font-bold font-lotuss">Serial</p>
                      <div className="flex flex-row">
                        <p className="text-base text-[#424242] break-words w-[95%]">{parseSerial(coupon.couponCode || '')?.displaySerial}</p>
                        <img
                          src="/icons/copy-icon.svg"
                          alt="copy-icon"
                          className="w-[18px] active:opacity-50"
                          onClick={() => {
                            copyTextToClipboard(parseSerial(coupon.couponCode || '')?.displaySerial)
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {[OFFERS_BZB_TYPE.REFILL].includes(type) && isExpired && !expireIn ? <TimerDisplay initialTime={0} setTimeout={setIsTimeout} timeout={isExpired && expireIn === 0} isDisabledTimer /> : <></>}
                {[OFFERS_BZB_TYPE.COUNTDOWN, OFFERS_BZB_TYPE.NON_COUNTDOWN].includes(type) && !isTimeout ? (
                  <BarcodeQRCode
                    coupon={coupon}
                    onCopy={() => {
                      setIsCopied(true)
                      setTimeout(() => setIsCopied(false), 3000)
                    }}
                  />
                ) : (
                  <></>
                )}
                {[OFFERS_BZB_TYPE.COUNTDOWN].includes(type) ? <TimerDisplay initialTime={isExpired ? 0 : expireIn} setTimeout={setIsTimeout} timeout={isTimeout} /> : <></>}
              </CardDetail>
            )}
          </div>
        )}
      </Transition>

      {typeOfLuckyDraw && (
        <ModalLuckyDraw
          idElement="coupons-special-modal"
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
          onClick={() => {
            setIsShowModal(!isShowModal)
          }}
          type={typeOfLuckyDraw}
          privilegeMessage={privilegeMessageOfLuckyDraw || null}
        />
      )}
      {[OFFERS_BZB_TYPE.LUCKY_DRAW].includes(type) ? (
        <div id="coupons-special-details-footer" className={classNames(styleFooter, { hidden: !isShowingSelectedCoupon })}>
          <Button
            title="ตรวจสอบรางวัล"
            disabled={isShowModal}
            onClick={() => {
              fetchCouponById(couponId)
            }}
          />
        </div>
      ) : (
        <div id="coupons-special-details-footer" className={classNames(styleFooter, { hidden: isHideFooterOpenExternalLink || !isShowingSelectedCoupon })}>
          <Button title="ใช้เลย" disabled={isLoading} onClick={() => window.open(url, '_blank')} />
        </div>
      )}
    </Fragment>
  )
}

export default CouponsSpecialStoreDetails
